@import 'theme';

.c3-grid line {
  stroke: $brand-gray-light;
}

.c3-xgrid,
.c3-ygrid {
  stroke-dasharray: 0;
}

.c3-tooltip th {
  background-color: $brand-gray-sand;
}
